








import {Component, Prop, Vue} from "vue-property-decorator";

@Component({name: 'EcpIcon'})
export default class EcpIcon extends Vue {
  @Prop({type:Boolean, default: false})
  private active!: boolean

  @Prop({type:String, default: ''})
  private type!: string

  get styles(){
    return {'ecp-icon_active': this.active}
  }
}
